'use client';
import AppButton from '@/app/_ui/buttons/AppButton';
import Dialog from '@/app/_ui/dialogs/Dialog';
import React, { createContext, useCallback, useContext, useState } from 'react';

interface ConfirmOptions {
  title?: string;
  description?: string;
  confirmMessage?: string;
  cancelMessage?: string;
  confirmButtonType?: 'error' | 'success' | 'warning' | 'white' | 'transparent';
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface ConfirmContextType {
  confirm: (options: ConfirmOptions) => void;
}

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context.confirm;
};

export const ConfirmProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [confirmState, setConfirmState] = useState<{
    isOpen: boolean;
    options: ConfirmOptions;
  }>({
    isOpen: false,
    options: {},
  });

  const confirm = useCallback((options: ConfirmOptions): void => {
    setConfirmState({
      isOpen: true,
      options,
    });
  }, []);

  const handleClose = useCallback(() => {
    setConfirmState((prev) => ({ ...prev, isOpen: false }));
    confirmState.options.onCancel?.();
  }, [confirmState]);

  const handleConfirm = useCallback(() => {
    setConfirmState((prev) => ({ ...prev, isOpen: false }));
    confirmState.options.onConfirm?.();
  }, [confirmState]);

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Dialog
        isOpen={confirmState.isOpen}
        onClose={handleClose}
        title={confirmState.options.title ?? 'Confirmation'}
        description={confirmState.options.description}
      >
        <div className="mt-4 flex justify-end space-x-3">
          <AppButton
            onClick={handleClose}
            text={confirmState.options.cancelMessage ?? 'Cancel'}
            variant="outlined"
          />
          <AppButton
            onClick={handleConfirm}
            text={confirmState.options.confirmMessage ?? 'Confirm'}
            variant="primary"
            color={confirmState.options.confirmButtonType}
          />
        </div>
      </Dialog>
    </ConfirmContext.Provider>
  );
};
