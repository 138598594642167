import {
  Description,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
  Dialog as UIDialog,
} from '@headlessui/react';
import * as React from 'react';
import { Fragment, type ReactElement } from 'react';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactElement;
  description?: string | ReactElement;
  children: React.ReactNode;
}

export default function Dialog({
  isOpen,
  onClose,
  title,
  description,
  children,
}: DialogProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <UIDialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="bg-opacity-12 fixed inset-0 backdrop-blur-sm"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              backdropFilter: 'blur(4px)',
            }}
          />
        </TransitionChild>

        <div className="fixed inset-0 top-16 md:top-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl lg:max-w-3xl">
                <div className="px-4 py-5 sm:p-6">
                  {typeof title === 'string' ? (
                    <DialogTitle
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-50"
                    >
                      {title}
                    </DialogTitle>
                  ) : (
                    title
                  )}
                  {description &&
                    (typeof description === 'string' ? (
                      <Description className="mt-2 text-sm trext-gray-100">
                        {description}
                      </Description>
                    ) : (
                      description
                    ))}
                  <div className="mt-4">{children}</div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </UIDialog>
    </Transition>
  );
}
